.ab {
  &__main {
    position: relative;
    height: 800px;
  }

  &__top {
    &-logottl {
      position: absolute;
      bottom: 150px;
      left: 10%;
    }
    &-ttl {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &-img {
      position: relative;

      & > img {
        width: 100%;
      }
    }
    &-bgimg {
      text-align: right;
      position: relative;
      bottom: 190px;
      left: 190px;
      z-index: -1;
    }
  }
}

.bread {
  &__ul {
    margin-left: 90px;
    margin-top: 30px;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__li {
    list-style: none;
    margin-right: 16px;
    & > a {
      color: #9fa1a0;
      font-size: 12px;
      position: relative;
    bottom: 2px;
    }
    & > p {
      margin: 0;
      color: #9fa1a0;
      font-size: 12px;
    }
  }
}

.main {
  &__content {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
  }

  &__txt {
    text-align: center;
    color: $pink-sec;
  }

  &__panel {
    &-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 110px;
    }
  }

  &__card {
    background-image: url(../images/about/about_panel_bg01.png);
    background-repeat: no-repeat;
    position: relative;

    &-bg-reverse {
      background-image: url(../images/about/about_panel_bg02.png);
      background-position: right;
    }

    &-icontop {
      position: absolute;
      top: -10%;
      right: 55%;
    }

    &-icontop02 {
      position: absolute;
      bottom: 60px;
      right: 0;
    }

    &-icontop03 {
      position: absolute;
      top: -20%;
      right: 50%;
    }
    &-icontop04 {
      position: absolute;
      bottom: 60px;
      right: -40px;
    }

    &-content {
      margin-bottom: 100px;
    }

    &-mainttl {
      & > h1 {
        color: #c2617c;
        text-shadow: 0px 0px 10px #fff;
        font-size: 28px;
        margin-bottom: 10px;
        position: relative;

        &::before {
          content: "";
          background-image: url(../images/about/about_panel_num01.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 100%;
          height: 60px;
          position: absolute;
          top: -25px;
          left: -230px;
          z-index: -1;
        }
      }
    }

    &-mainttl02 {
      & > h1 {
        color: #c2617c;
        text-shadow: 0px 0px 10px #fff;
        font-size: 28px;
        margin-bottom: 10px;
        position: relative;

        &::before {
          content: "";
          background-image: url(../images/about/about_panel_num02.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 100%;
          height: 60px;
          position: absolute;
          top: -25px;
          left: -235px;
          z-index: -1;
        }
      }
    }

    &-mainttl03 {
      & > h1 {
        color: #c2617c;
        text-shadow: 0px 0px 10px #fff;
        font-size: 28px;
        margin-bottom: 10px;
        position: relative;

        &::before {
          content: "";
          background-image: url(../images/about/about_panel_num03.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 100%;
          height: 60px;
          position: absolute;
          top: -25px;
          left: -235px;
          z-index: -1;
        }
      }
    }

    &-mainttl04 {
      & > h1 {
        color: #c2617c;
        text-shadow: 0px 0px 10px #fff;
        font-size: 28px;
        margin-bottom: 10px;
        position: relative;

        &::before {
          content: "";
          background-image: url(../images/about/about_panel_num04.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 100%;
          height: 60px;
          position: absolute;
          top: -25px;
          left: -237px;
          z-index: -1;
        }
      }
    }

    &-txt {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }

    &-ttl {
      width: 44%;
      position: relative;
      &-600 {
        width: 600px;
      }
    }

    &-icon {
      display: inline-block;
      position: relative;
      bottom: 40px;
      left: 30px;
    }

    &-img {
      width: 44%;
      // height: 300px;
      position: relative;
      bottom: 20px;

      & > img {
        width: 90%;
        // height: 100%;
        // object-fit: cover;
        border-radius: 15px;
      }
    }

    &-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 90%;
      max-width: 1000px;
      margin: 0 auto 100px;

      .main__card:nth-child(even) & {
        flex-direction: row-reverse;
        align-items: center;
      }
    }
  }
}
.card-txt-top {
  font-size: 18px;
  font-weight: bold;
  color: $pink-sec;
  // position: absolute;
  // left: 65px;
}
.card-txt {
  font-size: 14px;
  line-height: 2;
  margin-bottom: 0;
  // position: absolute;
  // bottom: 60px;
  // left: 65px;
}
