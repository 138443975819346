@media (max-width: 780px) {
  

  .bread {
    &__ul {
      margin-left: 30px;
      font-size:14px;
    }
  }

  .ab {
    &__top {
      &-logottl {
        width: 500px;
        text-align: center;
    

        & > img {
          width: 90%;
        }
      }
    }
  }

  .header {
    &__inner {
      width: 93%;
    }
  }

  .bar {
    height: 170px;
    line-height: 1;
    // padding: 5px 0;
    box-sizing: border-box;

    &__inner {
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 100%;
      width: 100%;
    }

    &__ttl {
      &-en {
        font-size: 24px;
        display: block;
        text-align: center;
      }
      &-ja {
        font-size: 13px;
        text-align: center;
        display: block;
        margin-top: 10px;
        margin-left: 0;
      }
    }

    &__left {
      width: 350px;
    }

    &__icon {
      width: 50px;

      & > img {
        width: 100%;
      }
    }
  }

  .machine {
    &__parts {
      &-wrap {
        justify-content: space-between;
      }
      &-blk {
        width: 47%;
        &-wrap {
          flex-direction: column;
        }
      }
    }

    &__ttl {
      & > h1 {
        font-size: 20px;
      }
      &-sub {
        font-size: 14px;
      }
    }
   
  }

  .info {
    &__inner {
      margin: 0 auto 90px;
    }

    &__ttl {
      margin-top: 30px;
    }
    &__li {
      & > a {
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
    }
    &__time {
      display: block;
    }
  }

  .pager {
    margin: 110px 0 100px 0;
  
    &__prev {
        margin-right: 15px;
    }
    &__prev {
        margin-left: 15px;
    }
  }
}
