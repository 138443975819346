.acc {
    &__inner {
        width: 90%;
        max-width:1000px;
        margin: 100px auto;
    }

    &__flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__right {
        &-img {
            & > img {
                border-radius: 10px;
            }
        }
    }

    &__img {
        width: 150px;
        margin-bottom: 20px;

        & > img {
            width: 100%;
        }
    }

    &__txt {
        & > p {
            margin-bottom: 0;
            font-size: 14px;
            line-height: 1.7;
        }

        & > div {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }
}

.map {
    background-image: url(../images/machine/back.png);
    background-position: top;
    background-repeat: no-repeat;
    padding-bottom: 200px;

    &__inner {
        width: 90%;
        max-width: 1200px;
        margin: 70px auto;
    }
}

iframe {
    border-radius: 10px;

}