.faq {
  &__inner {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
  }

  &__wrap {
    margin: 90px 0 0 0;
  }

  &-a {
    padding-right: 10px;
    font-size: 30px;
    color: $pink-sec;
    @extend .fo-ru;
    @extend .italic;

    & + p {
      margin: 0;
      font-size: 14px;
      line-height: 1.8;
    }
  }

  &__link {
    padding-top: 80px;
    margin-top: -80px;
  }
}
.anker {
  &__ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  &__li {
    list-style: none;

    & > a {
      color: $pink-sec;

      &:hover {
        opacity: 1;
        color: $pink-sec;
      }

      & > img {
        padding-right: 5px;
      }
    }
  }
}

/*アコーディオン全体*/
.accordion-area {
  list-style: none;
  width: 96%;
  max-width: 900px;
  margin: 0 auto;
}

.accordion-area li {
  margin: 10px 0;
}

.accordion-area section {
  border: 1px solid #ccc;
}

/*アコーディオンタイトル*/
.title {
  position: relative; /*+マークの位置基準とするためrelative指定*/
  cursor: pointer;
  font-size: 14px;
  transition: all 0.5s ease;
  background-color: #ebc9d1;
  margin-bottom: 0;
  border-radius: 30px;
  padding: 2px 0;
  margin-top: 25px;
  z-index: 2;
  height: 35px;

  & > img {
    margin-right: 10px;
    width: 34px;
  }
}

/*アイコンの＋と×*/
.title::before,
.title::after {
  position: absolute;
  content: "";
  width: 15px;
  height: 2px;
  background-color: $pink-sec;
}
.title::before {
  top: 48%;
  right: 2%;
  transform: rotate(0deg);
}
.title::after {
  top: 48%;
  right: 2%;
  transform: rotate(90deg);
}
/*　closeというクラスがついたら形状変化　*/
.title.close::before {
  transform: rotate(45deg);
}

.title.close::after {
  transform: rotate(-45deg);
}

/*アコーディオンで現れるエリア*/
.box {
  display: none; /*はじめは非表示*/
  background: #eeeeef;
  padding: 6px 20px 10px 20px;
  position: relative;
  bottom: 16px;
  z-index: 1;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  &__inner  {
    display: flex;
    align-items: baseline;
    padding:18px 0px 10px 0px;
  }
}

.mt-top {
  margin-top: 70px;
}

.link {
  & > a {
    color: #231815;
  }
}

.pb-lr {
  padding-bottom: 100px !important;
}
