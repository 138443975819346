.info {

    &__inner {
        width: 90%;
        max-width: 1000px;
        margin: 0 auto;
    }
  &__ttl {
    text-align: center;
    font-size: 20px;
    margin-bottom: 70px;
  }

  &__ul {
      padding: 0;
      margin: 0;
  }

  &__li {
    list-style: none;
    & > a {
      color: #464646 !important;
      border-bottom: 1px solid #969696;
      padding-bottom: 15px;
      margin-bottom: 25px;
      display: block;
      font-size: 14px;



      & > span {
        color: $pink-sec;
        margin-right: 30px;
      }
    }
  }

}

.pager {
  text-align: center;
  margin: 130px 0 200px 0;

  &__num {
      padding: 0 15px;
      color: #464646;

     
  }

  &__prev {
      margin-right: 80px;
  }
  &__prev {
      margin-left: 80px;
  }
}
.current02 {
    background-color: $pink;
    width: 40px;
    height: 40px;
    display: inline-block;
    line-height: 2.3;
    border-radius: 50%;
    color: #fff;

    &:hover {
        color:#fff;
    }
}