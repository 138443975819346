@media screen and (max-width: 480px) {
  .ab {
    &__top {
      &-logottl {
        width: 380px;
        left: 50%;
        bottom: 90px;
        transform: translateX(-50%);

      }

      &-bgimg {
        left: 80px;
      }
    }
  }
  .main {
    &__content {
      margin: -60px auto;
    }

    &__txt {
      margin-top: 60px;
    margin-bottom: 120px;
    }
  }

  .hero {
   
    &__images {
      &-bottom {
        padding: 17px 0;

        & > img {
          height: 100%;
          object-fit: cover;
        }
        & > p {
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }

    &__img01 {
      &-sp {
        position: absolute;
        top: 10%;
        left: 10%;

        & > img {
          width: 50%;
        }
      }
    }

    &__img02 {
      &-sp {
        position: absolute;
        top: 55%;
        left: 2%;

        & > img {
          width: 70%;
        }
      }
    }

    &__star01 {
      &-sp {
        position: absolute;
        top: 83%;
        left: 10%;

        & > img {
          width: 50%;
        }
      }
    }
    &__star02 {
      &-sp {
        position: absolute;
        top: 20%;
        left: 60%;

        & > img {
          width: 50%;
        }
      }
    }
    &__star03 {
      &-sp {
        position: absolute;
        top: 4%;
        right: -2%;

        & > img {
          width: 50%;
        }
      }
    }
  }

  .center {
    &__icon {
      & > img {
        width: 80%;
      }
    }

    &__machine {
      width: 40%;
      margin: 0 auto 40px;
      text-align: center;

      &-wrap {
        flex-wrap: wrap;
      }
    }

    &__bg {
        height: 450px;
    }
  }

  .title {
      &-w {
          width: 80%;
      }
     
  }
  
  .card-txt {
    font-size: 14px;
  }
}
