// html {
//   scroll-behavior: smooth;
// }
body {
  font-family: "Noto Sans JP", sans-serif;
  color: #231815;
}
#global-container {
  overflow: hidden;
}

// 共通パーツ
$pink: #d695a7;
$pink-sec: #c2617c;

a {
  &:hover {
    color: $pink;
    text-decoration: none;
  }
}

.ttl-ja {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-bottom: -7px;
 
}
.ttl-en {
  font-size: 50px;
  font-weight: bold;
  color: #fff;
  @extend .fo-ru;
  @extend .italic;
}

.ttl-ja-sec {
  font-size: 18px;
  font-weight: bold;
  color: $pink-sec;
  margin-bottom: -7px;
  text-align: center;
}
.ttl-en-sec {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 5px;
  color: $pink-sec;
  @extend .fo-ru;
  @extend .italic;
}

//フォント種類
.fo-ru {
  font-family: "Rubik", sans-serif;
}
.italic {
  font-style: italic;
}

.bg-col {
  background-color: $pink;
}

.flex {
  display: flex;
  align-items: center;
}

.bg-img {
  background-image: url(../images/top/hero_img.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.mobile-menu {
  &__btn {
    display: none;
  }
}



// pc表示
.hero {
  // height: 100vh;
  &__images {
    @extend .bg-img;
    background-image: url(../images/top/hero_img.jpg);
    background-position: -380px 0;
    position: relative;
    z-index: -1;
    // bottom: 100px;

    &::after {
      content: "";
      display: block;
      padding-top: 43%;
    }

    // sp表示
    &-sp {
      display: none;
    }

    &-bottom {
      background-color: $pink-sec;
      text-align: center;
      padding: 25px 0;
      & > img {
        width: 35%;
        // margin-top: -100px;
        // padding-bottom: 100px;
      }
      & > p {
        margin-top: 25px;
        margin-bottom: 0;
        color: #fff;
        font-size: 14px;
      }
    }
  }

  &__img01 {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 20%;

    & > img {
      width: 90%;
    }
  }
  &__img02 {
    position: absolute;
    top: 48%;
    left: 43%;

    & > img {
      width: 100%;
    }
  }

  &__star01 {
    position: absolute;
    top: 10%;
    left: 5%;
  }
  &__star02 {
    position: absolute;
    top: 30%;
    left: 45%;
  }
  &__star03 {
    position: absolute;
    top: 15%;
    right: 5%;
  }
  &__star04 {
    position: absolute;
    top: 18%;
    right: 30%;
  }
}

// メイン
.main {
  text-align: center;
  padding: 150px 0 90px 0;
  background-image: url(../images/main_bg.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  // margin-top: -100px;

  &__inner {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
  }

  &__top {
    &-ttl {
      &-sp {
        display: none;
      }
    }
  }

  &__free {
    & > img {
      width: 80%;
    }

    &-sp {
      display: none;
      margin-bottom: 70px;

      & > img {
        width: 95%;
      }
    }
  }
  &__txt {
    margin-top: 20px;
    margin-bottom: 70px;
    & > p {
      letter-spacing: 0.03rem;
      line-height: 2;
    }
  }
  &__free {
    margin-bottom: 100px;
  }

  &__flex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    z-index: 10;
    margin-bottom: 60px;
  }

  &__left {
    width: 45%;
    // height: 350px;

    & > img {
      width: 100%;
      // height: 100%;
    }
  }

  &__right {
    width: 45%;

    &-img01 {
      // width: 440px;
      & > img {
        width: 100%;
        // position: relative;
        // top: 20px;
      }
    }
    &-img02 {
      & > img {
        width: 100%;
        // position: relative;
        // bottom: 20px;
      }
    }
  }
}

//ニュース
.news {
  background-color: $pink;
  text-align: center;
  padding: 80px 0;

  &__inner {
    margin: 30px auto 50px auto;
    width: 800px;
    box-sizing: border-box;
    padding: 30px 50px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 18px;
    border: 4px solid #fff;
  }

  &__ul {
    margin: 0;
    padding: 0;
  }

  &__li {
    list-style: none;
    margin-bottom: 13px;
    border-bottom: 1px solid #fff;
    padding: 0 0 8px 0;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }

    & > a {
      display: flex;
      flex-direction: row;
      color: $pink-sec;
      margin-bottom: 13px;
      margin: 0;

      & > span {
        margin-right: 30px;
        @extend .fo-ru;
        font-weight: 500;
      }

      & p {
        margin-bottom: 0;
      }
    }
  }
}

// マシーン
.machine {
  text-align: center;
  background-image: url(../images/main_bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  padding: 100px 0 300px 0;
}
.ma {
  &__br {
    display: none;
  }
  &__ttl {
    &-main {
      font-size: 18px;
      letter-spacing: 0.03rem;
      color: $pink;
      font-weight: bold;
      margin-bottom: 10px;
    }

    &-sub {
      font-size: 14px;
      letter-spacing: 0.03rem;
    }
  }

  &__panel {
    & > img {
      width: 100%;
    }
    &-inner {
      width: 90%;
      max-width: 1000px;
      margin: 100px auto -30px;
      position: relative;
      z-index: 1;
    }
    &-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__bg {
    &-flower {
      position: absolute;
      bottom: -120px;

      & > img {
        width: 100%;
      }
    }
  }
}


.footer {
  background-color: #323232;
  height: 230px;
  width: 100%;
  @extend .flex;
  position: relative;
  

  &__add {
    font-size: 14px;
  }
  &__sp {
    display: none;
  }

  &__inner {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    color: #fff;
  }

  &__scroll {
    background-image: url(../images/scroll.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100px;
    position: fixed;
    bottom: 200px;
    right: 5px;
    z-index: 10;
    &::before {
        content: '';
        display: block;
        padding-top: 60%;
    }
 }

  &__pc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }


  &__right {
      width: 85%;
  }

  &__nav {
      border-bottom: 1px solid #fff;
      padding-bottom: 20px;
  }

  &__ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }

  &__li {
      list-style: none;

      & a {
          color: #fff;
          font-size: 14px;
          &:hover {
              opacity: 0.8;
          }

          & > span {
              width: 8px;
              height: 8px;
              background: #d695a7;
              display: inline-block;
              border-radius: 50%;
              margin: 0 4px 1px 0;
          }
      }
  }

  &__bottom {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 60px;
  }

  &__add {
    & p {
        margin-bottom: 0;
    }
  }

  &__co {
    font-size: 12px;
  }
  &__br {
    display: none;
  }
}
