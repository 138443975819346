@media (max-width: 600px) {
  

  .logo {
    padding-left: 20px;

    & a > img {
      width: 80%;
    }
  }
  .time {
    font-size: 12px;
  }
  .tel {
    font-size: 16px;
    &__blk {
      padding: 0 20px;
    }
  }

  .num {
    &__tel {
      width: 150px;
      margin: 0 auto;
      & > img {
        width: 100%;
      }
    }
  }

  .hero {
    &__images {
      &-sp {
        & > img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .main {
    padding: 50px 0 70px 0;

    &__inner {
      width: 92%;
    }

    &__txt {
      
      & > p {
        font-size: 14px;
      }
    }

    &__free {
      margin-bottom: 70px;
      & > img {
        width: 90%;
      }
    }

    &__left {
      margin-bottom: 30px;
    }

    &__card {
      &-img {
        text-align: center;
        width: 100%;
      }
    }
  }
  .img-md {
    width: 70%;
  }

  .ma {
    &__panel {
      width: 80%;
      
    }
  }

  
  .machine {
    &__img {
      width: 140px;

      & > img {
        width: 100%;
      }
    }
  }

  .btn {
    width: 90%;
  }

  .slider {
    width: 100%;
    margin:  30px auto 60px;
  }

  .slider img {
    height: 100%;
    // object-fit: cover;
  }
  .slider .slick-slide {
    margin: 0 10px;
  }

  .box {
    padding: 6px 15px 10px 15px;

    &__inner {
      padding: 15px 0px 10px 0px;
    }
  }

  .title {
    height: 50px;

    &-w {
      margin-right: 25px;
    }

    & > img {
      width: 51px;
    }
  }
  
  .faq {

    &-a {
      font-size: 40px;

      & + p {
        position: relative;
    bottom: 5px;
      }
    }
    &__link {
      padding-top: 60px;
      margin-top: -60px;
    }
  }
.mt-top {
  margin-top: 50px;
}

.map {
  padding-bottom: 100px;
}
  .footer {
    &__add {
      margin-left: 0;
      line-height: 2;

      & p {
        font-size: 11px;
      }
    }
    &__inner {
      width: 92%;
    }
    &__right {
      position: relative;
      top: 20px;
    }
    &__br {
      display: block;
    }
  }
}
