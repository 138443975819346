// メニュー表示
.mobile-menu {
  &__ul {
    padding: 0 0 0;
    margin: 0;
  }

  &__nav {
    padding-top: 10px;
  }

  &__link {
    margin-bottom:10px;
  }
  &__link02 {
    font-size: 24px !important;
    color: #fff !important;

    & > img {
      width: 25px;
      margin-right: 7px;
    }
  }

  &__span {
    font-size: 16px;
    color: #C2617C;
    @extend .italic;
  }
  &__span02 {
    font-size: 13px;
    color: #fff;

  }

  &__li {
    width: 80%;
    height: 50px;
    // background-color: #ffff;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(6) {
      margin-bottom: -3px;
    }

    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 2px;
      background-color: #C2617C;
      position: absolute;
      bottom: -0px;

    }

    & > a {
      display: block;
      width: 100%;
      font-size: 14px;
      color: #323232;
      font-weight: 600;
    }
  }

  &__btn {
    background-color: transparent;
    border: none;
    outline: none !important;
    cursor: pointer;
    padding: 0 10px;
    height: 55px;
    background: #fff;
    border-radius: 15px;

    & > span {
      background-color: #E898B0;
      width: 35px;
      height: 2px;
      display: block;
      margin-bottom: 9px;
      transition: transform 0.7s;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__cover {
    position: fixed;
    height: 100%;
    top: 75px;
    left: 0;
    right: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
    background: rgba(255, 255, 255, 0.9);
    transition: all 0.6s;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100%);
  }

  &__personimages {
    display: none;
  }
}

// メニューオープンした時
.menu-open {
  & .mobile-menu {
    &__cover {
      visibility: visible;
      transform: none;
      opacity: 1;
    }
    &__btn {
      & > span {
        background-color: #E898B0;

        &:nth-child(1) {
          transition-delay: 70ms;
          transform: translateY(11px) rotate(135deg);
        }
        &:nth-child(2) {
          transition-delay: 0s;
          transform: translateX(-18px) scaleX(0);
        }
        &:nth-child(3) {
          transition-delay: 140ms;
          transform: translateY(-11px) rotate(-135deg);
        }
      }
    }
  }
}

.mobile-menu {
}
